import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MdxLayout from 'components/mdx-layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <MdxLayout title="Configuration" subline="Create your own style system via configuration.">
    {children}
  </MdxLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "sass-default-variables"
    }}>{`Sass `}<inlineCode parentName="h2">{`!default`}</inlineCode>{` variables`}</h2>
    <p>{`In Sass variables can be flagged as `}<inlineCode parentName="p">{`!default`}</inlineCode>{`. That means that if another variable with the same name is defined in the scope of the project the `}<inlineCode parentName="p">{`!default`}</inlineCode>{` variable will be overwritten (learn more about it `}<a parentName="p" {...{
        "href": "https://sass-lang.com/documentation/variables#default-values"
      }}>{`here`}</a>{`).`}</p>
    <p>{`Backbreeze's configuration API is based on this principle. To configure the values for a property class you need to overwrite it by creating a variable with the same name in your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// project config

// This declaration will overwritte each instance
// of the \`!default\` $bb-colors variable

$bb-colors: (
  'white': #fff,
  'black': #000,
  'primary': #006cd6,
  'accent': #f8c326,
  'danger': #cb2431,
  'current': currentColor,
);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// backbreeze config default

// Default value

$bb-colors: (
  'white': #fff,
  'black': #000,
  'current': currentColor,
) !default;
`}</code></pre>
    <p>{`Due to the way default variables work in Sass you need to overwrite any Backbreeze variables `}<strong parentName="p">{`before`}</strong>{` including the Backbreeze `}<inlineCode parentName="p">{`_config.scss`}</inlineCode>{` file (see `}<a parentName="p" {...{
        "href": "/installation/#including-in-your-project"
      }}>{`installation`}</a>{`).`}</p>
    <p>{`Backbreeze configuration variables are categorized in:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#property-driven-variables"
          }}>{`Property-driven`}</a></strong></li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#general-variables"
          }}>{`General`}</a></strong></li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#internal-variables"
          }}>{`Internal`}</a></strong></li>
    </ul>
    <h2 {...{
      "id": "property-driven-variables"
    }}>{`Property-driven variables`}</h2>
    <p>{`Each CSS property class has two configuration variables, one that holds the property's `}<a parentName="p" {...{
        "href": "#values"
      }}>{`values`}</a>{`, and one that holds `}<a parentName="p" {...{
        "href": "#configuration"
      }}>{`configuration`}</a>{` for that property (e.g. name, breakpoints, pseudo states), both variables are Sass maps.`}</p>
    <p>{`All variables have the same pattern:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-<PROPERTY_NAME>-values: (
  /* values */
);

$bb-<PROPERTY_NAME>-config: (
  /* configuration */
);
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Example:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-text-align-values: (
  /* values */
);

$bb-text-align-config: (
  /* configuration */
);
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <h3 {...{
      "id": "values"
    }}>{`Values`}</h3>
    <p>{`The values variable follow a simple key value pattern where the key will be the name for the value that is used in the resulting utility class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-text-align-values: (
  'c': center,
);

// will yield:

.ta-c {
  text-align: center;
}
`}</code></pre>
    <p>{`If the variable is false or the map is empty no classes will be generated.`}</p>
    <h4 {...{
      "id": "note-empty-map-keys"
    }}>{`NOTE: Empty map keys`}</h4>
    <p>{`Using an empty map key will result in a class without a value reference.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$main-font-size: 18px;

$bb-font-size-values: (
  '': $main-font-size,
  'lg': 22px,
);
`}</code></pre>
    <p>{`Will yield:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.fs {
  font-size: 18px;
}

.fs-lg {
  font-size: 22px;
}
`}</code></pre>
    <h4 {...{
      "id": "note-using-special-characters-in-map-keys"
    }}>{`NOTE: Using special characters in map keys`}</h4>
    <p>{`To have nice classes some of the default values use characters that need to be escaped in the generated CSS e.g.:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="lh-1.5">
  <!-- ... -->
</div>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.lh-1\\.5 {
  line-height: 1.5;
}
`}</code></pre>
    <p>{`Backbreeze will escape `}<inlineCode parentName="p">{`.`}</inlineCode>{`, `}<inlineCode parentName="p">{`/`}</inlineCode>{`, and `}<inlineCode parentName="p">{`%`}</inlineCode>{` automatically.`}</p>
    <p>{`Other special characters will need to be escaped using `}<inlineCode parentName="p">{`\\\\`}</inlineCode>{`:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-line-height-values: (
  '1.4': 1.4, // <= Automatically escaped
  '2': 2,
  '1\\\\*2': 2, // <= \`*\` needs to be escaped
);
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <h4 {...{
      "id": "note-negative-values"
    }}>{`NOTE: Negative values`}</h4>
    <p>{`Negative values are generated automatically for CSS properties to whom it is useful by default. These properties are defined in the internal variable `}<a parentName="p" {...{
        "href": "#bb-negative-properties"
      }}><inlineCode parentName="a">{`$bb-negative-properties`}</inlineCode></a>{`. Adding a positive value will also generate a negative counterpart:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-top-values: (
  '10': 10px,
);
`}</code></pre>
    <p>{`Will yield:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.top-10 {
  top: 10px;
}

.-top-10 {
  top: -10px;
}
`}</code></pre>
    <p>{`Negative values / classes can also be generated by prefixing value keys with a dash (`}<inlineCode parentName="p">{`-`}</inlineCode>{`). Be aware that this pattern does not make the value automatically negative, you have to take care of that yourself. This feature is useful for non strictly numeric values like transforms.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-transform-values: (
  '-50\\\\%': translate(-50%, -50%),
  // ...,
);
`}</code></pre>
    <p>{`Will yield:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.-tf-50\\% {
  transform: translate(-50%, -50%);
}
`}</code></pre>
    <h3 {...{
      "id": "configuration"
    }}>{`Configuration`}</h3>
    <p>{`The configuration map has three mandatory keys:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'name'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<String>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defines the generated class name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'prop'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<String>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CSS property used`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'values'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Map>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Values used, `}<strong parentName="td">{`this should be the corresponding values variable`}</strong></td>
        </tr>
      </tbody>
    </table>
    <p>{`Optional keys:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'bps'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Map>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set this will generate a responsive utility classes. Typically this will be set to `}<a parentName="td" {...{
              "href": "#bb-bps"
            }}><inlineCode parentName="a">{`$bb-bps`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'pseudo'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Map>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set this will generate pseudo selector utility classes. Typically this will be set to `}<a parentName="td" {...{
              "href": "#bb-pseudo"
            }}><inlineCode parentName="a">{`$bb-pseudo`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'important'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Boolean>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set the properties will be made `}<inlineCode parentName="td">{`!important`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-text-align-config: (
  'name': 'ta',
  'prop': 'text-align',
  'values': $bb-text-align-values,
  'bps': $bb-mqs,
);
`}</code></pre>
    <h2 {...{
      "id": "general-variables"
    }}>{`General variables`}</h2>
    <p>{`General variables are used to define values for multiple properties. It is recommended to customize these for every project.`}</p>
    <h3 {...{
      "id": "bb-namespace"
    }}><inlineCode parentName="h3">{`$bb-namespace`}</inlineCode></h3>
    <p>{`Type `}<inlineCode parentName="p">{`<String>`}</inlineCode>{`. Namespace generated class names, it defaults to an empty string, that means classes are not namespaced by default. In case you want to use Backbreeze with another library or just want to make clear what classes are utility classes you can set a namespace to prevent naming collisions.`}</p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-namespace: '' !default;
`}</code></pre>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-namespace: 'bb';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- .ta-center {
+ .bb-ta-center {
    text-align: center;
  }
`}</code></pre>
    <p>{`Setting `}<inlineCode parentName="p">{`$bb-namespace: 'bb-';`}</inlineCode>{` will make all classes look like this: `}<inlineCode parentName="p">{`.bb-<REST OF THE CLASS>`}</inlineCode>{` (`}<inlineCode parentName="p">{`.-bb-<REST OF THE CLASS>`}</inlineCode>{`) for negative classes.`}</p>
    <h3 {...{
      "id": "bb-bps"
    }}><inlineCode parentName="h3">{`$bb-bps`}</inlineCode></h3>
    <p>{`Type `}<inlineCode parentName="p">{`<Map>`}</inlineCode>{`, the keys will be used in the responsive class names. Used in: all classes that are responsive.`}</p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-bps: (
  'xs': 416px,
  'sm': 756px,
  'md': 1024px,
  'lg': 1220px,
  'xl': 1460px,
) !default;
`}</code></pre>
    <h3 {...{
      "id": "bb-spacing"
    }}><inlineCode parentName="h3">{`$bb-spacing`}</inlineCode></h3>
    <p>{`Defines spacing. Used in: `}<inlineCode parentName="p">{`padding`}</inlineCode>{`, `}<inlineCode parentName="p">{`margin`}</inlineCode>{`, `}<inlineCode parentName="p">{`top`}</inlineCode>{`, `}<inlineCode parentName="p">{`left`}</inlineCode>{`, `}<inlineCode parentName="p">{`right`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom`}</inlineCode>{`.`}</p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-spacing: (
  '1': 1px,
  '2': 2px,
  '3': 3px,
  '4': 4px,
  '': 26px,
) !default;
`}</code></pre>
    <h3 {...{
      "id": "bb-sizes"
    }}><inlineCode parentName="h3">{`$bb-sizes`}</inlineCode></h3>
    <p>{`Defines sizes. Used in: `}<inlineCode parentName="p">{`width`}</inlineCode>{`, `}<inlineCode parentName="p">{`max-width`}</inlineCode>{`, `}<inlineCode parentName="p">{`min-width`}</inlineCode>{`, `}<inlineCode parentName="p">{`height`}</inlineCode>{`, `}<inlineCode parentName="p">{`max-height`}</inlineCode>{`, `}<inlineCode parentName="p">{`min-height`}</inlineCode>{`, `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`.`}</p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-sizes: (
  '1': 1px,
  '2': 2px,
  '3': 3px,
  '4': 4px,
  '': 26px,
) !default;
`}</code></pre>
    <h3 {...{
      "id": "bb-colors"
    }}><inlineCode parentName="h3">{`$bb-colors`}</inlineCode></h3>
    <p>{`Defines colors. Used in: `}<inlineCode parentName="p">{`background-color`}</inlineCode>{`, `}<inlineCode parentName="p">{`border-color`}</inlineCode>{`, `}<inlineCode parentName="p">{`color`}</inlineCode>{`, `}<inlineCode parentName="p">{`outline-color`}</inlineCode>{`, `}<inlineCode parentName="p">{`fill`}</inlineCode>{`, `}<inlineCode parentName="p">{`stroke`}</inlineCode></p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-colors: (
  'white': #fff,
  'black': #000,
  'current': currentColor,
) !default;
`}</code></pre>
    <h2 {...{
      "id": "internal-variables"
    }}>{`Internal variables`}</h2>
    <p>{`Internal variables that should not no customization.`}</p>
    <h3 {...{
      "id": "bb-directional-properties"
    }}><inlineCode parentName="h3">{`$bb-directional-properties`}</inlineCode></h3>
    <p>{`Type `}<inlineCode parentName="p">{`<List>`}</inlineCode>{`. List of CSS properties that have directional values (e.g. padding-left, margin-bottom).`}</p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-directional-properties: (
  'padding',
  'margin',
  'border',
  'border-width',
  'border-style',
  'border-color',
  'border-radius'
) !default;
`}</code></pre>
    <h3 {...{
      "id": "bb-negative-properties"
    }}><inlineCode parentName="h3">{`$bb-negative-properties`}</inlineCode></h3>
    <p>{`Type `}<inlineCode parentName="p">{`<List>`}</inlineCode>{`. All values for the properties in this list will have a corresponding negative value (and corresponding class name) generated automatically.`}</p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-negative-properties: ('margin', 'top', 'left', 'bottom', 'right') !default;
`}</code></pre>
    <p>{`Negative classes are prefixed by a dash:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.-top-10 {
  top: -10px;
}
`}</code></pre>
    <h3 {...{
      "id": "bb-pseudo"
    }}><inlineCode parentName="h3">{`$bb-pseudo`}</inlineCode></h3>
    <p>{`Type `}<inlineCode parentName="p">{`<List>`}</inlineCode>{`. Pseudo classes to generate for a given property.`}</p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-pseudo: ('hover', 'focus', 'active', 'group-hover') !default;
`}</code></pre>
    <p>{`Group hover is used to allow for nested elements that respond to hover interaction with its parent `}<inlineCode parentName="p">{`.group`}</inlineCode>{` element:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a class="group">
  <h3 class="c-primary:group-hover">
    Hovering the group will change the coolor of the text
  </a>
</a>
`}</code></pre>
    <h3 {...{
      "id": "bb-overflow"
    }}><inlineCode parentName="h3">{`$bb-overflow`}</inlineCode></h3>
    <p>{`Type `}<inlineCode parentName="p">{`<List>`}</inlineCode>{`. Overflow values used for the properties `}<inlineCode parentName="p">{`overflow`}</inlineCode>{`, `}<inlineCode parentName="p">{`overflow-x`}</inlineCode>{`, `}<inlineCode parentName="p">{`overflow-y`}</inlineCode>{`.`}</p>
    <p>{`Default value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$bb-overflow: (
  'hidden': hidden,
  'visible': visible,
  'scroll': scroll,
  'auto': auto,
) !default;
`}</code></pre>
    <h2 {...{
      "id": "recommended-configuration"
    }}>{`Recommended configuration`}</h2>
    <p>{`The following variables will are the most likely to be customized for every project that uses Backbreeze:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// _vars.scss

// Breakpoints
$bb-bps: (/* ... */);

// Spacing
$bb-spacing: (/* ... */);

// Sizes
$bb-sizes: (/* ... */);

// Colors
$bb-colors: (/* ... */);

// Font Family
$bb-font-family-values: (/* ... */);

// Font Sizes
$bb-font-size-values: (/* ... */);

// Line height
$bb-line-height-values: (/* ... */);
`}</code></pre>
    {
      /* @TODO */
    }
    {
      /* - Abstract vs. concrete (use values like 'large': 40px, vs. '20': 20px,) */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      